.mobile-control {
  position: relative;
  margin-bottom: 30px;

  &__title {
    position: relative;
    margin: 0 0 46px;
    max-width: 616px;
    //padding-right: 70px;
  }

  &__body {
    padding: 50px 50% 50px 40px;
    border-radius: 15px;
    background-image: url("../img/app-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    &::after {
      content: "";
      position: absolute;
      top: 35px;
      right: 35px;
      display: inline-block;
      width: 42px;
      height: 42px;
      margin-left: 10px;
      vertical-align: middle;
      background-image: url("../img/app_logo.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &-mobile__body {
    display: none;
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    &:not(:last-child) {
      margin: 0 0 10px;
    }
  }

  &__item-text {
    @include font(16px, 24px, 400);
    color: $text-main;
    display: flex;
    padding: 20px;
    border-radius: 15px;
    transition: color 0.3s;

    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.5);
    }

    & span:first-child {
      flex-shrink: 0;
      margin-right: 24px;
    }

    // &.hovered {
    //   color: $text-main;
    // }

    // &.hovered + .mobile-control__item-img {
    //   visibility: visible;
    //   opacity: 1;
    //   transition-delay: 0s;
    // }
  }

  &__item-radio {
    &:checked + label {
      color: $text-main;
      background-color: #fff;
      box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);

      span:first-child {
        font-weight: 500;
        font-size: 17px;
      }
    }

    &:checked + label + .mobile-control__item-img {
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;
    }
  }

  &__item-img {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 50%;
    right: 25%;
    width: 410px;
    height: 527px;
    margin-right: -200px;
    margin-top: -280px;
    transition: opacity 0.2s, visibility 0s ease 0.2s;
  }

  &__app-block-container {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  &__app-block {
    display: flex;
  }

  &__app-link {
    width: 174px;
    height: 64px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &:first-child {
      margin-right: 16px;
    }

    &--appstore {
      background-image: url("../img/appstore-icon.svg");
    }

    &--googleplay {
      background-image: url("../img/googleplay-icon.svg");
    }
  }

  &__app-title {
    @include font(24px, 28px);
    display: flex;
    align-items: center;
    margin-left: auto;

    img {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
  }
}

@include mobile {
  .mobile-control {
    &__body {
      display: none;
    }

    &-mobile__body {
      display: block;
      max-width: 620px;
      margin: 0 auto;
    }

    &__app-block {
      flex-wrap: wrap;
      justify-content: center;
    }

    &__app-title {
      width: 100%;
      order: 1;
      justify-content: center;
      margin: 0 0 20px;
    }

    &__app-link--appstore {
      order: 2;
    }

    &__app-link--googleplay {
      order: 3;
    }
  }
}

@include mobile-small {
  .mobile-control {
    &__app-title {
      flex-direction: column;
      text-align: center;

      img {
        margin: 0 0 20px;
      }
    }

    &__app-link {
      &:first-child {
        margin: 0 0 10px;
      }
    }

    &__app-block {
      max-width: 330px;
      margin: 0 auto;
    }
  }
}
