.cloud-advantages {
  padding-top: 138px;
  padding-bottom: 40px;
  background-color: #fff;

  &__diagnostics-block {
    background-color: #fff;
  }

  &__diagnostics {
    min-height: 320px;
    padding: 80px 58% 160px 0;
    background-image: url("../img/diagnostics.svg");
    background-repeat: no-repeat;
    background-position: top right 96px;
  }

  &__title {
    @include font(28px, 32px);
    color: $text-main;
    max-width: 460px;
    margin: 0 0 40px;
  }

  &__desc {
    @include font(16px, 24px);
    margin: 0;
  }

  &__comfort-payment-block {
    background-color: #fff;
    padding-bottom: 100px;
  }

  &__comfort-payment {
    min-height: 226px;
    padding: 10px 0 0 58%;
    background-image: url("../img/comfort-payment.svg");
    background-repeat: no-repeat;
    background-position: top left 120px;

    .cloud-advantages__title {
      max-width: 500px;
    }
  }
}

@include tablet {
  .cloud-advantages {
    &__diagnostics {
      background-size: 45% auto;
      background-position: top 23% right 60px;
    }

    &__comfort-payment {
      background-position: top left 70px;
    }
  }
}

@include mobile {
  .cloud-advantages {
    max-width: 668px;
    margin: 0 auto;
    background-color: transparent;

    &__title {
    }

    &__diagnostics {
      padding-bottom: 100px;
      background-position: top 40% right 60px;
    }

    &__comfort-payment {
      background-size: 35% auto;
      background-position: top 50% left 30px;
    }
  }
}

@include mobile-small {
  .cloud-advantages {
    padding-top: 80px;

    &__title {
      @include font(22px, 25px);
      margin-bottom: 30px;
    }

    &__diagnostics {
      padding: 50px 0 250px;
      background-position: bottom center;
      background-size: 320px auto;
    }

    &__comfort-payment {
      padding: 120px 0 230px;
      background-position: bottom center;
      background-size: 250px auto;
    }

    &__comfort-payment-block {
      padding-bottom: 70px;
    }
  }
}
