.how-it-works {
  position: relative;
  padding-top: 120px;
  overflow: hidden;
  background-color: #fff;

  &__bg {
    position: absolute;
    top: 130px;
    right: -300px;
    width: 812px;
    height: 496px;

    img {
      width: 812px;
      height: 496px;
    }
  }

  &__container {
    position: relative;
    padding-bottom: 100px;
    z-index: 10;
  }

  &__title {
    @include font(24px, 32px);
    color: $text-minor;
    margin: 0 0 24px;
  }

  &__body {
    display: flex;
    margin-bottom: 80px;
    //flex-wrap: wrap;
  }

  &__left-side {
    max-width: 630px;
    flex-shrink: 1;
    padding-right: 30px;
  }

  &__desc {
    @include font(40px, 48px);
    color: $text-main;
    margin: 0 0 45px;
  }

  &__stages-list {
    margin: 0 0 52px;
    padding: 0;
  }

  &__stages-item {
    @include font(16px, 24px, 400);
    color: $text-main;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 32px;
    }

    & span:first-child {
      @include font(42px, 63px, 400);
      width: 30px;
      height: 63px;
      margin-right: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      color: $primary;
    }

    & span:last-child {
      max-width: 367px;
      padding-right: 20px;
    }
  }

  &__service-block {
    margin-top: 48px;
  }

  &__service-note {
    @include font(24px, 36px);
    color: $text-main;
    margin: 0 0 36px;
  }

  &__service-list {
    margin: 0;
    padding: 0;
    display: flex;
  }

  &__service-item {
    @include font(16px, 24px);
    position: relative;
    color: $text-main;
    padding-left: 40px;
    width: 280px;

    &:not(:last-child) {
      margin-right: 38px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background-image: url("../img/check.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__right-side {
    flex-grow: 1;
    max-width: 560px;
    margin-left: auto;
    flex-shrink: 0;
  }

  &__right-side-title {
    @include font(24px, 36px);
    color: #000;
    margin: 0 0 10px;
    text-align: center;
  }

  &__right-side-desc {
    @include font(14px, 21px);
    color: #000;
    margin: 0 0 35px;
    text-align: center;
  }

  &__img-block {
    margin-bottom: 30px;
    text-align: center;

    img {
      width: 400px;
      height: auto;
    }
  }

  &__device-name {
    @include font(14px, 22px);
    color: $text-main;
    margin: 0 0 53px;
    text-align: center;
  }

  &__payment {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 10px;
    padding: 22px;
    border: 1px solid #eee;
    background-color: #fff;
    border-radius: 10px;
  }

  &__payment-first {
    text-align: center;
  }

  &__payment-note {
    @include font(14px, 21px);
    color: $text-minor;
    margin: 0;
  }

  &__payment-plus {
    @include font(24px, 36px, 500);
    color: $primary;
    margin: 0 40px;
  }

  &__payment-first-value {
    @include font(18px, 27px, 400);
    color: $text-main;
    margin: 0 0 5px;
  }

  &__payment-subscribe {
    text-align: center;
  }

  &__payment-subscribe-value {
    margin: 6px 0 0;
    @include font(20px, 24px, 400);
    color: $text-main;
    padding: 16px 20px;
    background: linear-gradient(115.18deg, #F0EDFE 9.16%, #D6EBFF 84.65%);
    border-radius: 5px;
  }

  &__payment-conditions {
    @include font(12px, 18px);
    color: #000;
    text-align: center;
    margin: 0 0 40px;
  }

  &__subscription-desc {
    p {
      color: #000;
      text-align: center;

      &:first-child {
        @include font(24px, 36px);
        margin: 0 0 10px;
      }

      &:nth-child(2) {
        @include font(14px, 21px);
        margin: 0;
      }
    }
  }

  &__payment-link {
    display: block;
    width: fit-content;
    padding-left: 34px;
    padding-right: 34px;
    margin: 0 auto;
  }

  &__alert {
    @include font(18px, 24px, 400);
    position: relative;
    padding: 38px 32px 38px 100px;
    background-color: rgba(255, 0, 0, 0.03);
    border-radius: 15px;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 32px;
      display: block;
      width: 48px;
      height: 48px;
      background-image: url("../img/alert-2.svg");
      background-size: contain;
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }
  }
}

@include tablet {
  .how-it-works {
  }
}

@include mobile {
  .how-it-works {
    &__stages-list {
      flex-direction: column;
      max-width: 640px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 100px;
    }

    &__stages-item {
      min-height: 63px;

      span {
        vertical-align: middle;
        &:last-child {
          max-width: none;
          align-self: center;
        }
      }
    }

    &__body {
      flex-direction: column;
      max-width: 620px;
      margin-left: auto;
      margin-right: auto;
    }

    &__left-side {
      padding-right: 0;
    }

    &__right-side {
      margin: 0 auto;
    }

    &__alert {
      max-width: 620px;
      margin: 0 auto;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .header__how-it-works li.how-it-works__stages-item {
    max-width: none;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  .header__how-it-works li.how-it-works__stages-item span:nth-child(2) {
    padding-top: 0;
  }
}

@include mobile-small {
  .how-it-works {
    &__title {
      text-align: center;
    }

    &__bg {
      top: 650px;
      width: 100%;
      height: auto;
      right: -25%;

      img {
        width: 100%;
        height: auto;
      }
    }

    &__desc {
      @include font(32px, 38px);
      text-align: center;
    }

    &__stages-list {
      margin-bottom: 100px;
    }

    &__payment {
      flex-direction: column;
    }

    &__alert {
      @include font(16px, 21px);
    }
  }
}
