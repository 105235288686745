.service-content {
  padding-top: 30px;
  background-color: #fff;

  &__title {
    @include font(40px, 48px);
    margin: 0 0 30px;
    max-width: 700px;
  }

  &__list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    width: calc((100% - 35px) / 2);
    padding: 33px;
    padding-right: 270px;
    margin-bottom: 35px;
    border-radius: 15px;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
    background-position: right -50px center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-color: #fff;

    &--app {
      background-image: url("../img/service-app.jpg");
    }

    &--warranty {
      background-image: url("../img/service-warranty.jpg");
    }

    &--diagnostic {
      background-image: url("../img/service-diagnostic.jpg");
    }

    &--support {
      background-image: url("../img/service-support.jpg");
    }
  }

  &__item-title {
    @include font(20px, 24px, 400);
    color: #000;
    margin: 0 0 10px;
  }

  &__item-desc {
    @include font(16px, 24px);
    color: #000;
    margin: 0;
  }
}

@include tablet {
  .service-content {
    &__item {
      min-height: 210px;
      padding: 30px 130px 30px 20px;
      background-position: right -140px center;
    }
  }
}

@include mobile {
  .service-content {
    padding-bottom: 80px;

    &__container {
      max-width: 668px;
      margin: 0 auto;
    }

    &__list {
      flex-direction: column;
    }

    &__item {
      width: 100%;
      min-height: 168px;
      padding-right: 30%;
      background-size: 110% auto;
      background-position: right -50px center;
    }
  }
}

@include mobile-small {
  .service-content {
    padding-bottom: 50px;

    &__title {
      @include font(32px, 38px);
      text-align: center;
    }

    &__item {
      min-height: 152px;
      background-size: auto 100%;
      background-position: right -100px center;
    }

    &__item-title {
      @include font(16px, 19px, 400);
    }

    &__item-desc {
      @include font(12px, 18px);
    }
  }
}
