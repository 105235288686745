.to-top {
  @include btn-reset;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #444;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  transition: color 0.3s,
              opacity 0.3s,
              visibility 0s ease 0.3s;

  rect {
    fill: #fff;
  }

  &:hover {
    cursor: pointer;
    color: white;

    rect {
      transition: fill 0.7s;
      fill: #00adef;
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
}

@include mobile-small {
  .to-top {
    transform: scale(0.8);
    transform-origin: right bottom;
  }
}
