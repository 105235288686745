.btn-primary,
.btn-secondary,
.btn-small {
  @include font(18px, 24px, 400);

  display: inline-block;
  height: auto;
  padding: 21px 24px 19px 24px;
  margin: 0;
  text-decoration: none;
  text-align: center;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  transition: all .2s ease;
}

.btn-small {
  padding-top: 9px;
  padding-bottom: 9px;
}

.btn-primary,
.btn-small {
  background-color: #5ea9ff;
  color: #fff;

  &:hover,
  &:focus {
    background-color: #3392ff;
    color: #fff;
  }

  &:disabled {
    background-color: $disabled;
    color: #fff;
  }
}

.btn-secondary {
  padding: 14px 39px;
  border: 1px solid $stroke;
  color: $text-main;
  background-color: #fff;

  &:hover,
  &:focus {
    border-color: #5ea9ff;
    color: #5ea9ff;
    background-color: #fff;
  }

  &:disabled {
    border-color: $disabled;
    color: $disabled;
    background-color: #fff;
  }
}
