.header {
  position: relative;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  padding-top: 140px;
  background-color: #fff;
  overflow: hidden;

  &__bg {
    position: absolute;
    display: inline-block;
    width: 50%;
    top: 17%;
    left: 65%;
    transform: translateY(-50%);

    img {
      width: 100%;
      height: auto;
    }
  }

  &__bar {
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px 0;
    width: 100%;
    z-index: 200;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  &__bar-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 13px 0 15px;
    background-color: #fff;
    transform: translateY(-100%);
    transition: transform 0.3s;
    border-bottom: 1px solid #DDDDDD;
    background-color: #fff;
    z-index: 100;

    & .header__nav-link {
      color: #000;
    }

    & .header__logo {
      width: 74px;
      height: 52px;
    }

    &.opened {
      transform: translateY(0);
    }
  }

  &__bar-container {
    display: flex;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
  }

  &__container {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    z-index: 10;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    max-width: 1160px;
    flex-grow: 1;
    margin: 0 auto 50px;
  }

  &__left-side {
    max-width: 560px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__logo {
    @include font(0, 0);
    width: 150px;
  }

  &__logo-title {
    margin: 0 0 20px;

    img {
      width: 220px;
      height: auto;
    }
  }

  &__nav {
    margin: 0 0 0 auto;
  }

  &__nav-list {
    padding: 0;
    margin: 0;
    display: flex;
  }

  &__nav-item {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  &__nav-link {
    @include font(16px, 18px);
    color: #000;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      cursor: pointer;
      color: #5ea9ff;
    }
  }

  &__body {
    position: relative;
    padding-top: 120px;
    padding-bottom: 44px;
    z-index: 10;
  }

  &__video-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: 0.6;
    }
  }

  &__body-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__title-block {
    display: flex;
    justify-content: center;
    margin-bottom: 75px;
  }

  &__title-logo {
    width: 200px;
    height: 141px;
    margin-right: 50px;

    img {
      width: 200px;
      height: 141px;
    }
  }

  &__title {
    @include font(32px, 38px, 400);
    margin: 0 0 30px;
    color: #000;
  }

  &__desc {
    @include font(18px, 28px, 400);
    color: #707070;
    margin: 0 0 30px;
  }

  &__buy-link {
    @include font(15px, 23px, 400);
    padding: 16px 0;
    width: 300px;
  }

  &__right-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    justify-content: center;
  }

  &__cloud-logo {
    margin: 0 0 20px;

    img {
      width: 120px;
      height: auto;
    }
  }

  &__cloud-title {
    @include font(28px, 33px);
    color: #000;
    margin: 0 0 20px;
    text-align: center;
  }

  &__device-block {
    margin: 0 0 30px;

    img {
      width: 500px;
      height: auto;
    }
  }

  &__img-block {
    display: flex;
    justify-content: center;
  }

  &__img-picture {
    width: 880px;
    height: 269px;
  }

  &__price-block {
    @include font(18px, 24px, 400);
  }

  &__price-title {
    color: #fff;
    margin-right: 15px;
  }

  &__price {
    @include font(20px, 30px, 500);
    display: inline-block;
    padding: 10px 21px;
    background-color: #fff;
    border-radius: 12px;
    color: #fff;
    background-image: url("../img/btn-bg.svg");
    background-size: cover;
    margin: 0 0 30px;
  }

  &__how-it-works {
    width: 100%;

    .how-it-works__stages-list {
      display: flex;
      width: 100%;
      padding-top: 20px;
      align-items: flex-start;
      justify-content: space-between;
      border-top: 1px solid #eee;
    }

    li.how-it-works__stages-item {
      margin: 0;
      max-width: 350px;
      align-items: flex-start;

      span:nth-child(2) {
        padding-top: 10px;
      }
    }
  }

  &__bottom {
    position: relative;
    margin-top: auto;
    z-index: 10;
    padding-bottom: 30px;
  }

  &__brands-list {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    padding-top: 40px;
    padding-bottom: 76px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }

  &__brands-item {
    &:not(:last-child) {
      margin-right: 160px;
    }
  }

  &__benefits-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__benefits-item {
    position: relative;
    padding-left: 39px;
    @include font(14px, 21px);
    color: #000;
    width: calc((100% - 24px) / 2);
    margin-bottom: 20px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background-image: url("../img/check.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__nav-btn {
    display: none;
  }
}

@include tablet {
  .header {
    &__device-block {
      img {
        width: 370px;
      }
    }

    &__right-side {
      max-width: 640px;
    }

    &__bar-container {
      padding-left: 24px;
      padding-right: 24px;
    }

    &__logo {
      width: 90px;
    }

    &__benefits-list {
      flex-direction: column;
    }

    &__benefits-item {
      width: 100%;
    }

    &__nav {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      min-height: calc(var(--vh, 1vh) * 100 - 64px);
      align-items: center;
      background-color: #fff;
      display: none;
      z-index: 300;
    }

    &__nav-list {
      width: 100%;
      padding-bottom: 100px;
      flex-direction: column;
      justify-content: center;
    }

    &__nav-item {
      text-align: center;

      &:nth-child(n) {
        margin: 0 0 20px;
      }
    }

    &__nav-link {
      @include font(18px, 21px);
    }

    &__nav-btn {
      @include btn-reset;
      position: relative;
      display: block;
      width: 22px;
      height: 14px;
      margin-left: auto;
      height: 22px;

      &:hover {
        cursor: pointer;
      }

      & span {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background-color: $text-main;

        &:first-child {
          top: 4px;
          transition: transform 0.3s;
          transform-origin: top left;
          transform: translate(0, 0) rotate(0deg);
        }

        &:nth-child(2) {
          top: 50%;
          transform: translateY(-50%);
          transition: height 0.3s;
        }

        &:last-child {
          bottom: 4px;
          transition: transform 0.3s;
          transform-origin: bottom left;
          transform: translate(0, 0) rotate(0deg);
        }
      }
    }

    &__bar.js-opened {
      .header__nav {
        display: flex;
      }

      .header__nav-btn {
        & span {
          &:first-child {
            transform: translate(4px, -1px) rotate(45deg);
          }

          &:nth-child(2) {
            height: 0;
          }

          &:last-child {
            transform: translate(4px, 2px) rotate(-45deg);
          }
        }
      }
    }

  }
}

@include mobile {
  .header {
    padding-top: 120px;

    &__bg {
      top: auto;
      bottom: 26%;
      left: 61%;
    }

    &__inner {
      flex-direction: column;
    }

    &__left-side {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 0 auto 60px;
    }

    &__logo-title {
      img {
        width: 180px;
      }
    }

    &__title {
      @include font(28px, 33px);
    }

    &__desc {
      @include font(16px, 25px);
      max-width: 450px;
    }

    &__right-side {
      margin: 0 auto;
    }

    &__device-block {
      img {
        width: auto;
        max-width: 370px;
      }
    }
  }
}

@include mobile-small {
  .header {
    &__device-block {
      margin-bottom: 50px;

      img {
        width: 370px;
        max-width: 100%;
      }
    }

    &__bg {
      left: auto;
      right: -24px;
      width: 90%;
      bottom: 50%;
      margin-bottom: -230px;
    }

    &__price {
      margin-bottom: 80px;
    }

    &__benefits-item {
      margin-bottom: 10px;
    }

    &__inner {
      margin-bottom: 30px;
    }
  }
}
