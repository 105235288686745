.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s linear 0.3s;
  z-index: 100;

  &.js-show {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.25);
    overflow-y: auto;

    &::after {
      content: "";
      width: 0;
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
  }

  &__body {
    position: relative;
    display: inline-block;
    max-width: 850px;
    width: 90%;
    margin-bottom: 100px;
    margin-top: 100px;
    padding: 28px;
    text-align: left;
    vertical-align: middle;
    background-color: #fff;
    border-radius: 5px;
  }

  &__title {
    @include h24;
    margin: 0;
    margin-bottom: 15px;
    color: $text-main;
  }

  &__desc {
    @include b14;

    margin-bottom: 28px;
    color: $text-minor;
  }

  // &__content {
  //   margin-bottom: 50px;
  // }

  &__btn-block {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 28px;
  }

  &__btn-item {

    &:last-child {
      margin-left: 15px;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  // &__close {
  //   position: absolute;
  //   top: 30px;
  //   right: 30px;
  //   width: 30px;
  //   height: 30px;
  //   padding: 0;
  //   margin: 0;
  //   border-radius: 0;
  //   background-image: url("../img/close_default.svg");
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   background-color: transparent;
  //   border: none;

  //   &:hover,
  //   &:focus,
  //   &:active {
  //     background-color: transparent;
  //     border: none;
  //   }

  //   &:hover {
  //     background-image: url("../img/close_hover.svg");
  //   }
  // }
}

@include mobile {
  .modal {
    overflow: hidden;
    height: calc(var(--vh, 1vh) * 100);
    opacity: 1;
    transition: visibility 0s linear 0.3s;

    &__overlay {
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 0.3s linear;

      &::after {
        display: none;
      }
    }

    &__body {
      position: absolute;
      left: 0;
      top: 100%;
      transition: top 0.3s;
      border-radius: 0;
      display: block;
      width: 100%;
      padding: 30px 15px 165px;
      max-width: none;
      height: 100%;
      margin: 0;
      overflow-y: auto;
      padding-bottom: 0;
    }

    &__title {
      @include h24;
      margin-bottom: 24px;
    }

    &__desc {
      @include b14;
    }

    &__content {
      //margin-bottom: 30px;
      padding-bottom: 110px;
    }

    &__content > div {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    // &__close {
    //   right: 15px;
    // }

    &__btn-block {
      position: fixed;
      top: calc(var(--vh, 1vh) * 100);
      left: 0;
      width: 100%;
      padding: 20px 15px 150px;
      transform: translateY(0);
      background-color: #fff;
      transition: transform 0.2s ease-in;
      z-index: 100;
    }

    &__btn-item {
      flex-grow: 1;

      &:last-child {
        margin-left: 14px;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .modal.js-show .modal__overlay {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .modal.js-show .modal__body {
    top: 0;
  }

  .modal.js-show .modal__btn-block {
    transform: translateY(calc(-100% + 100px));
  }
}

