.mobile-control-mobile {
  background-color: #fff;
  padding-bottom: 100px;
  padding-top: 80px;

  &__title {
    @include font(40px, 48px);
    color: $text-main;
    margin: 0 0 56px;
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    border-top: 1px solid #eee;

    &:last-child {
      border-bottom: 1px solid #eee;
    }
  }

  &__item-top {
    position: relative;
    @include font(18px, 24px);
    padding: 27px 60px 27px 0;
    color: #000;
    margin: 0;

    &:hover {
      cursor: pointer;

      svg {
        color: #00adeb;
      }
    }

    &.opened {
      .mobile-control-mobile__item-icon {
        transform: rotate(45deg);
      }
    }
  }

  &__item-icon {
    position: absolute;
    top: 50%;
    right: 0;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s;

    svg {
      transition: color 0.3s;
      color: #000;
    }
  }

  &__item-content {
    @include font(15px, 27px);
    max-width: 800px;
    padding-bottom: 40px;
    margin: 0;
    display: none;

    ul {
      margin: 10px 0;
      padding: 0;
    }

    li {
      list-style: disc;
      list-style-position: inside;
    }
  }
}

@include mobile-small {
  .mobile-control-mobile {
    &__item-top {
      @include font(16px, 21px);
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}
