.device-card {
  background-color: #fff;
  padding: 36px;
  border-radius: 15px;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
  display: flex;

  .swiper-slide {
    height: auto;
    display: flex;
    align-items: center;
    max-width: 100%;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #000;
  }

  &__slider-container {
    height: 100%;
  }

  &__image-block {
    position: relative;
    max-width: 360px;
    margin-right: 65px;
    padding-top: 8px;
  }

  &__logo {
    position: absolute;
    top: -10px;
    left: -1px;
    z-index: 10;

    img {
      width: 80px;
      height: auto;
    }
  }

  &__bages {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;

    img {
      width: 120px;
      height: auto;
    }
  }

  &__bages-item {
    margin-bottom: 1px;
  }

  &__title {
    @include font(28px, 43px, 500);
    margin: 0 0 15px;
    color: #000;
  }

  &__benefits-list {
    margin: 0;
    padding: 0;
    margin: 0 0 25px;
  }

  &__benefits-item {
    @include font(16px, 24px, 400);
    color: #000;
    padding-left: 35px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background-image: url("../img/check.svg");
      background-position: center;
      background-repeat: no-repeat;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__terms {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
  }

  &__terms-item {
    margin-bottom: 20px;

    &:not(:last-child) {
      margin-right: 60px;
    }
  }

  &__terms-title {
    @include font(16px, 24px, 500);
    margin: 0 0 10px;
    color: #5ea9ff;
  }

  &__terms-price-item {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__terms-price-block {
    display: flex;
  }

  &__terms-price {
    @include font(18px, 27px, 500);
    margin: 0;
    color: #000;
  }

  &__terms-price-desc {
    @include font(12px, 18px, 400);
    color: #888;
    margin: 0;
  }

  &__terms-item--grey {
    .device-card__terms-title,
    .device-card__terms-price {
      color: #888;
    }

    .device-card__terms-price {
      font-weight: 400;
      text-decoration: line-through;
    }
  }
}

@include tablet {
  .device-card {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 920px;

    &__image-block {
      max-width: 300px;
      margin-right: 30px;
    }

    &__terms-item {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}

@include mobile {
  .device-card {
    max-width: 620px;
    flex-direction: column;

    &__image-block {
      max-width: none;
      margin-right: 0;
    }

    &__slider-container {
      max-width: 300px;
    }

    &__bages {
      display: flex;
    }

    &__bages-item {
      &:first-child {
        margin-right: 7px;
      }
    }

    &__benefits-list {
      margin-bottom: 60px;
    }

    &__title {
      text-align: center;
    }

    &__terms {
      flex-direction: column-reverse;
    }

    &__terms-item {
      display: flex;
    }

    &__terms-title {
      max-width: 120px;
      margin-right: 30px;
    }

    .header__buy-link {
      width: 100%;
    }
  }
}

@include mobile-small {
  .device-card {
    .swiper-wrapper {
      min-height: 300px;
    }

    &__bages {
      flex-direction: column;
    }

    &__benefits-list {
      margin-bottom: 50px;
    }

    &__benefits-item {
      @include font(14px, 21px);

      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }

    &__terms-item {
      flex-direction: column;
      &:not(:last-child) {
        margin-right: 0;
      }
    }

    &__terms-title {
      max-width: none;
      margin-right: 0;
      @include font(14px, 21px, 400);
      margin-bottom: 5px;
    }

    &__terms-price-item {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 7px;
      }
    }

    &__terms-price {
      @include font(14px, 21px, 400);
      margin-left: auto;
      white-space: nowrap;
    }

    &__terms-price-desc {
      @include font(12px, 18px, 300);
      margin-right: 20px;
      white-space: nowrap;
    }

    &__terms-price-block {
      flex-direction: column;
    }
  }
}
