.all-benefits {
  padding-top: 80px;
  background-color: #fff;

  &__title {
    @include font(40px, 48px);
    color: $text-main;
    margin: 0 0 56px;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    @include font(16px, 24px);
    color: $text-main;
    position: relative;
    width: calc((100% - 50px) / 2);
    padding: 0 20px 0 50px;
    margin: 0 0 60px;



    & span:first-child {
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 30px;
      background-image: url("../img/check-round.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

@include tablet {
  .all-benefits {
    padding-top: 40px;
  }
}

@include mobile {
  .all-benefits {
    &__container {
      max-width: 668px;
    }

    &__list {
      flex-direction: column;
    }

    &__item {
      width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
}

@include mobile-small {
  .all-benefits {
    &__title {
      @include font(32px, 38px);
      margin: 0 0 40px;
    }
  }
}
