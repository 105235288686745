.catalog {
  padding-top: 80px;

  &__container {
  }

  &__title {
    @include font(48px, 56px);
    color: $text-main;
    max-width: 800px;
    margin: 0 auto 40px;
    text-align: center;
  }

  &__filters {
    margin-bottom: 45px;
  }

  &__filters-list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__filters-item {

  }

  &__filters-label {
    @include font(20px, 27px, 400);
    display: block;
    width: 112px;
    padding: 0 10px 18px;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    color: #888;
    text-align: center;
    border-bottom: 2px solid #eee;
    transition: color 0.3s;

    &:hover {
      cursor: pointer;
      color: #3392ff;
    }

    // &--daichi {
    //   display: block;
    //   width: 91px;
    //   height: 40px;
    //   background-image: url("../img/daichi-grey.svg");

    //   &:hover {
    //     background-image: url("../img/daichi-color.svg");
    //   }
    // }

    // &--daikin {
    //   display: block;
    //   width: 109px;
    //   height: 40px;
    //   background-image: url("../img/daikin-grey.svg");
    // }

    // &--bosch {
    //   display: block;
    //   width: 103px;
    //   height: 40px;
    //   background-image: url("../img/bosch-grey.svg");
    // }

    // &--midea {
    //   display: block;
    //   width: 104px;
    //   height: 64px;
    //   background-image: url("../img/midea-grey.svg");
    // }
  }

  &__filter-icon-grey {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &__filter-icon-color {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &__filters-radio:checked + .catalog__filters-label
  .catalog__filter-icon-grey {
    opacity: 0;
  }

  &__filters-radio:checked + .catalog__filters-label
  .catalog__filter-icon-color {
    opacity: 1;
  }

  &__filters-radio:checked + .catalog__filters-label {
    color: #5ea9ff;
    font-weight: 500;
    border-color: #5ea9ff;
  }

  &__list {
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 1160px;
  }

  &__item {
    position: relative;
    width: 100%;
    margin-bottom: 24px;

    .device-card {
      margin: 0 auto;
    }


    &.hidden {
      display: none;
    }
  }

  &__select-outer {
    position: relative;
    max-width: 420px;
    padding: 16px 20px;
    border: 2px solid #5ea9ff;
    color: #5ea9ff;
    display: none;
    margin: 0 auto;
  }

  &__select-block {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &::after {
      content: '';
      width: 2px;
      height: 0;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      border-color: #5ea9ff transparent transparent transparent;
      box-sizing: content-box;
    }

    select {
      height: 100%;
      width: 100%;
      appearance: auto;
      font-size: 16px;
      line-height: 18px;
      color: #000;
      opacity: 0;
    }
  }

}

@include mobile {
  .catalog {
    &__select-outer {
      display: block;
    }

    &__filters-list {
      display: none;
    }
  }
}
